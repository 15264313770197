<template>
  <transition name="modal">
    <div>
      <div class="overlay" @click.self="close()">
        <div class="modal">
          <span class="close" @click="close()">
            <label>X</label>
          </span>
          <div class="absensi">
            <div class="absensi-block">
              <div class="absensi-block-title">Keterangan</div>
              <div class="absensi-block-input">
                <input type="text" v-model="desc" class="w-full" />
              </div>
              <div class="mt-4 bg-grey-light p-3 rounded-lg">
                <p class="text-xs mb-1">Catatan:</p>
                <p class="text-xs"><strong>Absen Masuk</strong> dilakukan setelah sesi dimulai.</p>
                <p class="text-xs"><strong>Absen Keluar</strong> dilakukan setelah sesi selesai.</p>
              </div>
              <div class="flex justify-center mt-6">
                <Button buttonText="Absen" @action="submit()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'absensi-field',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      desc: 'Saya MENGIKUTI kelas hingga selesai'
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['absensiPrakerja']),
    close() {
      this.$emit('close')
    },
    submit() {
      this.showLoading()
      const type = this.data.state === 'masuk' ? 0 : 1
      this.absensiPrakerja({
        id: this.data.classId,
        payloads: {
          id: this.data.data.id,
          type: type,
          desc: this.desc
        }
      })
        .then(this.successAbsensi)
        .catch(() => {
          this.hideLoading()
          showVueToast('Absensi gagal, mohon untuk melakukan absensi pada waktu yang telah ditentukan!', 'error', 4000)
        })
    },
    successAbsensi() {
      this.hideLoading()
      this.$emit('successAbsen')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.modal {
  width: 650px;
  margin: 0px auto 0px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
  position: relative;
  @media screen and (max-width: 768px) {
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
  }
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    cursor: pointer;
    label {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
  @media screen and (max-width: 768px) {
    align-items: unset;
  }
}
.absensi {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    cursor: pointer;
    label {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-block {
    position: relative;
    padding: 30px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &-title {
      font-size: 16px;
      margin-bottom: 5px;
    }
    &-input {
      margin-bottom: 10px;
      input {
        outline: none;
        width: 350px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        color: #aaa;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &-submit {
      text-align: center;
      button {
        background-color: #f76748;
        color: #fff;
        padding: 5px 20px;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 5px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #b42b0d;
        }
      }
    }
  }
}
</style>
